import { useState } from 'react'
import RenderOutInputs from './RenderOutInputs'
import InputWrapper from '@components/search/forms/ActionBarAdvancedOptionsInputWrapper'
import Input from '@components/form/Input'
import RangeInput from '@components/form/RangeInput'
import RadioInput from '@components/form/RadioInput'
import ListOut from '@components/shared/ListOut'
import Complex from '@components/form/Complex'
import Icon from '@components/shared/Icon'
import useT from '@hooks/useTranslation'

import styles from 'styles/ActionBarAdvancedOptions.module.css'

let lastActivePane = null

const ActionBarAdvancedOptions = ({ breakpoints,
    children, ...props }) => {

    const [activePane, setActivePane] = useState(lastActivePane)
    const activatePane = (i) => {
        const newValue =
            i === activePane
                ? null
                : i

        lastActivePane = newValue
        setActivePane(newValue)
    }

    return (
        <div className={styles.outer_wrapper}>
            <div className={styles.wrapper}>
                <RenderPanes {...props}
                    activePane={activePane}
                    activatePane={activatePane}
                    mobile={breakpoints.mobile} />
            </div>

            { children && <div className={styles.other_actions}>
                { children }
            </div> }
        </div>

    )
}

function RenderPanes ({ advancedOptions, ...rest }) {
    return <ListOut id="t" items={advancedOptions} Component={Pane} {...rest} />
}

function Pane ({ i, t, tOptions, name, type, hint, inputs,
    methods, activePane, activatePane,
    mobile, ...rest }) {

    const renderStandalonePane = !t && !tOptions && !name
    const renderInputOnly = Boolean(name)
    const isActive = i === activePane || renderStandalonePane

    const inputProps = renderInputOnly && { // render input at pane level
        name,
        type,
        hint,
        label: useT(t),
        tOptions,
        inputs,
        methods,
        register: methods.register,
        ...rest,
    }

    const paneContentProps = isActive && {
        isActive,
        contentOrder: mobile
            ? paneContentOrderMobile(i)
            : paneContentOrderDesktop(i),
        inputs,
        methods,
    }

    if (renderStandalonePane) { // Standalone pane is always open
        return (
            <StandalonePaneWrapper>
                <PaneContent {...paneContentProps} />
            </StandalonePaneWrapper>
        )

    } else {                    // Multiple panes, only one is open (active) at a time
        const paneTitleProps = {
            t,
            isActive,
        }

        const paneWrapperProps = {
            isActive,
            onClick: renderInputOnly
                ? undefined
                : () => activatePane(i),
            paneOrder: i%2 === 0 ? i : i-1,
        }

        return (
            <>
                <PaneWrapper {...paneWrapperProps}>
                    { renderInputOnly
                        ? <InputWrapper>{renderInput(inputProps)}</InputWrapper>
                        : <PaneTitle {...paneTitleProps} /> }
                </PaneWrapper>
                <PaneContent {...paneContentProps} />
            </>
        )
    }
}

function PaneWrapper ({ paneOrder, onClick, isActive, children }) {
    return (
        <div onClick={onClick} className={styles.pane} style={{ order: paneOrder, backgroundColor: isActive ? 'var(--gray)' : 'white' }}>
            { children }
        </div>
    )
}

function PaneTitle ({ t, isActive }) {
    return (
        <div className={styles.pane_action}>
            {useT(t)}
            <Icon>{ isActive
                ? 'expand_more'
                : 'chevron_right'
            }</Icon>
        </div>
    )
}

function PaneContent ({ isActive, contentOrder, inputs, methods }) {
    return isActive
        ? (
            <div className={styles.pane_content} style={{ order: contentOrder }}>
                { inputs && <RenderOutInputs
                    shouldRenderWrapper={type => type === 'range'}
                    Wrapper={InputWrapper}
                    inputs={inputs}
                    methods={methods}
                    register={methods.register}
                    onChange={methods.onChange} /> }
            </div>
          )

        : null
}

function StandalonePaneWrapper ({ children }) {
    return (
        <div className={styles.standalone_pane}>
            { children }
        </div>
    )
}

function paneContentOrderMobile (i) {
    return i
}

function paneContentOrderDesktop (i) {
    return i%2 === 0
        ? i+1
        : i
}

function renderInput (inputProps) {
    const { tOptions, type, inputs, ...props } = inputProps

    if (!type || type === 'text') return <Input {...props} placeholder=' '/>
    if (type === 'range') return <RangeInput {...props} />
    if (type === 'radio') return <RadioInput {...props} tOptions={tOptions} />
    if (type === 'complex') return <Complex {...props} inputs={inputs} />
    return null
}

export default ActionBarAdvancedOptions