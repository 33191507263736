import ListOut from '@components/shared/ListOut'
import useT from '@hooks/useTranslation'
import styles from 'styles/Select.module.css'

const Select = ({
    defaultOption,
    emptyText = 'Wybierz',
    label = '',
    name,
    onChange,
    options,
    selectStyle = {},
    ...rest }) => {

    return (
        <label {...rest}>
            { label && <span className={styles.label}>{ useT(label) }</span> }
            <select {...{name, onChange, defaultValue: defaultOption, className: styles.select, style: selectStyle}}>
                { emptyText && <option value="">{useT(emptyText)}...</option> }
                <ListOut
                    id="t"
                    items={options}
                    Component={Option}
                />
            </select>
        </label>
    )
}

function Option ({ t, value }) {

    return <option value={(value==null?t:value)}>{useT(t)}</option>
}

export default Select