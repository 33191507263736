const SORT_OPTIONS = [
    { t: 'Najnowsze', value: 'Najnowsze' },
    { t: 'Popularne', value: 'Popularne' },
    { t: 'Najtańsze', value: 'Najtansze' },
    { t: 'Najdrozsze', value: 'Najdrozsze' },
    { t: 'W Pobliżu', value: 'Blisko' },
]

const NO_LOCATION_SORT_OPTIONS = SORT_OPTIONS.slice(0, -1)

export default SORT_OPTIONS
export {
    NO_LOCATION_SORT_OPTIONS,
}