import Input from './Input'

const baseProps = {
    label: 'Paliwo',
    name: 'paliwo',
}

const inputs = {
    paliwo: {
        ...baseProps,
        hint: { url: '/fuel' },
    },

    paliwoMotor: {
        ...baseProps,
        hint: { url: '/fuel_motor' },
    }
}

export default function FuelInputMobile ({ paliwo, ...props }) {
    if (typeof paliwo !== 'object') return null
    const choices = Object.keys(paliwo).length
    const isMotor = choices === 3
    const input = isMotor ? inputs.paliwoMotor : inputs.paliwo
    return <Input {...input} {...props} />
}