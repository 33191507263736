import { readFromStorage, writeInStorage } from '@utils/sessionStorageAccess'

const useFormDataKeeper = (formId) => {
    let store
    const key = `form-${formId}`
    const formDataKeeper = (formState) => {
        if (typeof window === 'undefined') return

        store = store || readFromStorage(key, {})

        for (const [name, value] of Object.entries(formState)) {
            if (value) {
                if (value !== store[name]) store[name] = value
            } else {
                if (store[name]) delete store[name]
            }
        }

        writeInStorage(key, store)
    }

    formDataKeeper.purge = () => {
        if (typeof window === 'undefined') return
        store = {}
        writeInStorage(key, store)
    }

    const formState = () => {
        const state = typeof window === 'undefined'
            ? {}
            : readFromStorage(key, {})

        formDataKeeper.purge() //

        return state
    }

    formDataKeeper.dataKey = formId
    formDataKeeper.storageKey = key

    return [formDataKeeper, formState]
}

export default useFormDataKeeper
