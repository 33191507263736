import {
    // CAR_LINK_BASE,
    TRUCK_LINK_BASE,
    // MOTORCYCLE_LINK_BASE,
    PART_LINK_BASE,
    ACCESSORY_LINK_BASE,

    // CAR_RENT_LINK_BASE,
    TRUCK_RENT_LINK_BASE,

    SERVICE_LINK_BASE,
    TOOL_LINK_BASE,
    AGRI_LINK_BASE,
    CONSTRUCTION_LINK_BASE,
    TRAILER_LINK_BASE,
    HEAVY_LINK_BASE,

    // BUY_LINK_BASE,

    // BUY_CAR_LINK_BASE,
    BUY_TRUCK_LINK_BASE,
    // BUY_MOTORCYCLE_LINK_BASE,
    // BUY_PART_LINK_BASE,
    // BUY_CONSTRUCTION_LINK_BASE,
    // BUY_TRAILER_LINK_BASE,
    // BUY_TOOL_LINK_BASE,
    // BUY_AGRI_LINK_BASE,

} from '@constants/searchFormLinks'

const ICON_DIMEN = { width: 28, height: 28 }

// const osobowe = { t: 'Osobowe', to: CAR_LINK_BASE, svg: 'osobowe', alt: 'Samochody osobowe', title: 'Samochody, samochody osobowe, samochody używane' }
const dostawcze = { t: 'Dostawcze', to: TRUCK_LINK_BASE, svg: 'dostawcze', alt: 'Samochody dostawcze', title: 'Samochody dostawcze, auta dostawcze, sprzedaż samochodów dostawczych' }
// const motocykle = { t: 'Motocykle', to: MOTORCYCLE_LINK_BASE, svg: 'motocykle', alt: 'Motocykle', title: 'Motocykle, sprzedaż motocykli' }
const czesci = { t: 'Części', to: PART_LINK_BASE, svg: 'czesci', alt: 'Części samochodowe', title: 'Części samochodowe, sprzedaż części' }
const akcesoria = { t: 'Akcesoria', to: ACCESSORY_LINK_BASE, svg: 'akcesoria', alt: 'Akcesoria', title: 'Akcesoria samochodowe' }
const wynajem = { t: 'Wynajem', to: TRUCK_RENT_LINK_BASE, svg: 'wynajem', alt: 'Wynajem samochodów', title: 'Wynajem samochodów, wynajem aut osobowych, wynajem samochodów dostawczych' }
const serwis = { t: 'Serwis', to: SERVICE_LINK_BASE, svg: 'serwis', alt: 'Serwis samochodowy', title: 'Oferty serwisów samochodowych' }
const narzedzia = { t: 'Narzędzia', to: TOOL_LINK_BASE, svg: 'narzedzia', alt: 'Narzędzia warsztatowe', title: 'Sprzedaż narzędzi warsztatowych' }
const rolnicze = { t: 'Rolnicze', to: AGRI_LINK_BASE, svg: 'rolnicze', alt: 'Maszyny rolnicze', title: 'Maszyny rolnicze, sprzedaż maszyn rolniczych, ogłoszenia maszyny rolnicze' }
const budowlane = { t: 'Budowlane', to: CONSTRUCTION_LINK_BASE, svg: 'budowlane', alt: 'Maszyny budowlane', title: 'Maszyny budowlane, sprzedaż maszyn budowlanych, ogłoszenia maszyn budowlanych' }
const przyczepy = { t: 'Przyczepy', to: TRAILER_LINK_BASE, svg: 'przyczepy', alt: 'Przczepy samochodowe', title: 'Przyczepy samochodowe, sprzedaż przyczep samochodowych, ogłoszenia przyczepy samochodowe' }
const ciezarowe = { t: 'Ciężarowe', to: HEAVY_LINK_BASE, svg: 'ciezarowe', alt: 'Samochody ciężarowe', title: 'Samochody ciężarowe, sprzedaż samochodów ciężarowych, ogłoszenia samochodów ciężarowych' }
const kupie = { t: 'Kupię', to: BUY_TRUCK_LINK_BASE, svg: 'kupno', alt: 'Kupię samochód', title: 'Kupię samochód osobowy, dostawczy' }
// const sprzedam = { t: 'Sprzedam', to: TRUCK_LINK_BASE, svg: 'sprzedaz', alt: '' }

// const buyOsobowe = { t: 'Osobowe', to: BUY_CAR_LINK_BASE, svg: 'osobowe', alt: 'Samochody osobowe', title: 'Samochody osobowe używane i nowe' }
const buyDostawcze = { t: 'Dostawcze', to: BUY_TRUCK_LINK_BASE, svg: 'dostawcze', alt: 'Samochody dostawcze', title: 'Samochody dostawcze ogłoszenia sprzedam' }
// const buyMotocykle = { t: 'Motocykle', to: BUY_MOTORCYCLE_LINK_BASE, svg: 'motocykle', alt: 'Motocykle', title: 'Motocykle ogłoszenia sprzedam' }
// const buyCzesci = { t: 'Części', to: BUY_PART_LINK_BASE, svg: 'czesci', alt: 'Części samochodowe', title: 'Części samochodowe ogłoszenia sprzedaży' }
// const buyNarzedzia = { t: 'Narzędzia', to: BUY_TOOL_LINK_BASE, svg: 'narzedzia', alt: 'Narzędzia warsztatowe', title: 'Sprzedaż narzędzi warsztatowych' }
// const buyRolnicze = { t: 'Rolnicze', to: BUY_AGRI_LINK_BASE, svg: 'rolnicze', alt: 'Maszyny rolnicze', title: 'Sprzedaż maszyn rolniczych' }
// const buyBudowlane = { t: 'Budowlane', to: BUY_CONSTRUCTION_LINK_BASE, svg: 'budowlane', alt: 'Maszyny budowlane', title: 'Sprzedaż maszyn budowlanych' }
// const buyPrzyczepy = { t: 'Przyczepy', to: BUY_TRAILER_LINK_BASE, svg: 'przyczepy', alt: 'Przczepy samochodowe', title: 'Sprzedaż przyczep samochodowych' }

const frontpageLinks = [
    dostawcze,
    ciezarowe,
    przyczepy,
    budowlane,
    rolnicze,
    czesci,
    serwis,
    narzedzia,
    wynajem,
    akcesoria,
    kupie,
    // osobowe,
    // motocykle,
]

const buyFrontpageLinks = [
    buyDostawcze,
    // sprzedam,
    // buyPrzyczepy,
    // buyBudowlane,
    // buyRolnicze,
    // buyCzesci,
    // buyNarzedzia,
    // buyOsobowe,
    // buyMotocykle,
]

export {
    frontpageLinks,
    buyFrontpageLinks,
    ICON_DIMEN,
}