import searchFromObject from '@utils/links/searchFromObject'
import searchSlugifyNumericParams from '@utils/links/searchSlugifyNumericParams'
import sortUrlParams from 'src/seo/server/sortUrlParams'
// import slug from '@utils/slug/createSlug'

const LOCATION = {
    field: 'lokalizacja',
    getSlug: {
        // new: lokalizacja => slug(lokalizacja, { lowerCase: false }),
        legacy: lokalizacja => lokalizacja,
    },
}

const MAP_VALUES = {
    paliwo: {
        'Elektryczne': 'Elektro'
    },
}

const searchFormLink = ({ data,
    linkBase,
    isMakeModelBase = true }) => {

        const { marka, model, lokalizacja, strona, ...rest } = data

        const locationBase = lokalizacja
            ? linkPart(LOCATION.field, LOCATION.getSlug.legacy(lokalizacja))
            : []

        if (isMakeModelBase) {
            const hasModelButNoMake = !!model && !marka
            const params =
                searchFromObject(
                    searchSlugifyNumericParams(
                        hasModelButNoMake
                            ? { model, ...rest }
                            : rest
                    ),
                    MAP_VALUES,
                )

            const makeModelBase = hasModelButNoMake
                ? []
                : linkPart(marka, marka && model)

            return clean([linkBase, ...makeModelBase, ...locationBase], params)

        } else {
            const params =
                searchFromObject(
                    searchSlugifyNumericParams(
                        { marka, model, ...rest }
                    ),
                    MAP_VALUES,
                )

            return clean([linkBase, ...locationBase], params)
        }
}

function linkPart () {
    return Array
        .from(arguments)
        .filter(Boolean)
        .map(val => encodeURIComponent(val))
}

function clean (array, params = '', char = '/') {
    return sortUrlParams(array.filter(Boolean).join(char) + params)
}

export default searchFormLink