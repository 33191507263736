import styles from 'styles/NavLinkColor.module.css'
export default function NavLinkButtonOverlay ({ children, color, overlay, style = {}, ...rest }) {
    const buttonClassName = `${styles.navlink} ${styles[color]}`
    const buttonStyle = {opacity:0.95,position:'relative', ...style}
    const hiddenStyle = {height:'100%',opacity:0,position:'absolute',width:'100%'}

    return (
        <button className={buttonClassName} style={buttonStyle} {...rest}>
            <span style={hiddenStyle}>{children}</span>
            {overlay}
        </button>
    )
}