function sortUrlParams (url, exclude = []) {
    const [base,search] = url.split('?')
    if (!search) return url
    const paramFilter = notIn(exclude)
    const sortedSearch = search.split('&').sort().filter(paramFilter).join('&')
    return sortedSearch
        ? `${base}?${sortedSearch}`
        : base
}

function notIn (exclude) {
    return param => exclude.every(omit => !param.startsWith(omit))
}

module.exports = sortUrlParams