import FUNCTIONS from './_searchNumericParamsSlugFunctions'

export default function searchSlugifyNumericParams (params) {
    const newParams = {}
    for (const key in params) {
        const toSlug = FUNCTIONS[key]?.toSlug
        const value = params[key]

        newParams[key] = toSlug
            ? value && toSlug(value)
            : value
    }

    return newParams
}