const DEFAULTS = {
    h1: 'Ogłoszenia',
    h2: 'Ogłoszenia samochodowe',

    title: 'dostawczaki.pl',
    description: 'dostawczaki.pl - serwis ogłoszeń kupna, sprzedaży, wynajmu samochodów.',

    image: 'https://dostawczaki.pl/assets/icons/large-samochody-fb.jpeg',
    imageWidth: 400,
    imageHeight: 400,
}

export default DEFAULTS