export default function numericSlug (num, milionSlugUnit = '-mln-',
	thousandSlugUnit = '-tys-', trailingSlugUnit = 'zl', keepUnit = false) {

	if (isNaN(num)) return 0
	const value = Number(num)

	if (value % 1000 !== 0) return keepUnit
		? `${num}${trailingSlugUnit}`
		: value

	const milions = parseInt(value / 1000000)
	const thousands = (value - (milions * 1000000)) / 1000

	const milionSlug = milions > 0
		? `${milions}${milionSlugUnit}`
		: ''

	const thousandSlug = thousands > 0
		? `${thousands}${thousandSlugUnit}`
		: ''

	return `${milionSlug}${thousandSlug}${trailingSlugUnit}`
}