import { useRouter } from 'next/router'
import Select from '@components/select/Select'
import createRouterCallback from '@components/select/functions/createRouterCallback'
import SORT_OPTIONS, { NO_LOCATION_SORT_OPTIONS } from '@components/select/values/sortOptions'
import withUser from '@hoc/withUser'
import hideOnMobile from '@hoc/hideOnMobile'

const SORT_NAME = 'sortuj'

const Sort = ({ label, user, isLoggedIn, ...rest }) => {
    const router = useRouter()
    const onChange = createRouterCallback(router, SORT_NAME)
    const enableGeoSort = Boolean(user?.location)
    return <Select
        defaultOption={router.query[SORT_NAME]}
        label={label !== null ? "Sortuj" : label}
        name={SORT_NAME}
        onChange={onChange}
        options={enableGeoSort
            ? SORT_OPTIONS
            : NO_LOCATION_SORT_OPTIONS}

        {...rest}
    />
}

export default hideOnMobile(
    withUser(Sort))