import Image from 'next/image'

import styles from 'styles/Background.module.css'

const Background = ({ src, height, alt, children, priority, collapseWhen = 'never', z }) => {
    return (
        <div className={collapseWhen
            ? `${styles.section} ${styles[collapseWhen]}`
            : styles.section}
            style={{ height: height || 427, zIndex: z }}>

            <div className={styles.image} key={src}>
                <Image
                    src={src}
                    alt={alt || ''}
                    layout="fill"
                    priority={priority}
                    objectFit="cover"
                />
            </div>

            { children }
        </div>
    )
}

export default Background