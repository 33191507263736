import { useRef, useEffect } from 'react'

const useEnter = (callback) => {
    const ref = useRef()

    useEffect(() => {
        const handler = (e) => {
            if (!ref.current) return
            if (e.key === 'Enter') {
                e.preventDefault()
                callback()
            }
        }

        document.addEventListener('keypress', handler)
        return () => document.removeEventListener('keypress', handler)
    }, [])

    return ref
}

export default useEnter