import { useState, useEffect } from 'react'
import { FUEL_CAPTIONS } from '@constants/fuels'
import useT from '@hooks/useTranslation'

import styles from 'styles/FuelButtons.module.css'

const ALTS = {
	'Benzyna': 'Samochody benzyna',
	'Diesel': 'Samochody diesel',
	'Hybryda': 'Samochody hybrydowe',
	'Elektro': 'Samochody elektryczne',
	'Gaz': 'Samochody na gaz',
}

const FuelInput = ({ name, defaultValue, options, register, setValue,
    dataKeeper, lastFormEventTime,
    singlevalue, required }) => {

    const [state, setState] = useState({...options})
    const [initialized, setInitialized] = useState(false)

    const arrayToState = (array) => {
        const newState = {}
        array.forEach(value => {
            newState[value] = true
        })

        return newState
    }

    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue)
            setState(arrayToState(
                singlevalue
                    ? [defaultValue]
                    : forceArray(defaultValue)
            ))
        }
    }, [defaultValue])

    useEffect(() => {
        if (initialized) {
            setState({...options})

        } else {
            const settings = required
                ? { required: 'Pole paliwo jest wymagane' }
                : undefined

            register(name, settings)
            setInitialized(true)
        }
    }, [lastFormEventTime])


    const stateToArray = (nextState) => {
        const array = []
        const currentState = nextState || state
        for (const key in currentState) {
            if (currentState[key]) array.push(key)
        }
        return array
    }

    const onChange = (value) => {
        if (!value || value.length < 1) value = ''
        setValue(name, value)
        if (dataKeeper) dataKeeper({ [name]: value })
    }

    const handleClick = (e) => {
        e.preventDefault()
        const { value } = e.target
        if (value) {

            if (singlevalue) {
                onChange(value)
                setState({ [value]: true })

            } else {
                const set = Boolean(state[value])
                const newState = { ...state, [value]: !set }
                const array = stateToArray(newState)

                onChange(array)
                setState(newState)
            }
        }
    }

    return <FuelButtons
        options={options}
        state={state}
        handleClick={handleClick} />
}

function FuelButtons ({ options, state = {}, handleClick }) {
    return Object
        .keys(options)
        .map(key => {

            const alt = ALTS[key]
            const displayName = FUEL_CAPTIONS[key] || key
            const id = key.toLowerCase()
            const isActive = Boolean(state[key])
                ? ' ' + styles.active
                : ''

            return (
                <button
                    key={id}
                    value={key}
                    title={alt}
                    onClick={handleClick}
                    className={`${styles.fuel_button}${isActive}`}>

                    <img width="20" height="25" src={`/svg/${id}.svg`} alt={alt} />
                    { useT(displayName) }

                </button>
            )
        })
}

function forceArray (value) {
    return Array.isArray(value)
        ? value
        : value.split(',')
}

export default FuelInput