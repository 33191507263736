import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import NavLinkButton from '@components/shared/NavLinkButton'
import NavLinkButtonOverlay from '@components/shared/NavLinkButtonOverlay'
import Sort from '@components/select/Sort'
import Icon from '@components/shared/Icon'
import withBreakpoints from '@hoc/withBreakpoints'

export default function withMobileSearchFormButtons (Component) {
    return withBreakpoints({ onMobile: 800 })(
        function MobileSearchFormButtons (props) {
            const { breakpoints: { onMobile }, ...rest } = props
            const [showButtons, setShowButtons] = useState(false)
            const [showForm, setShowForm] = useState(false)

            const router = useRouter()

            useEffect(() => {
                if (onMobile) {
                    setShowButtons(true)
                    setShowForm(false)

                } else {
                    setShowButtons(false)
                    setShowForm(true)
                }
            }, [onMobile, router])

            const toggleForm = () => {
                setShowForm(true)
                setShowButtons(false)
            }

            const Filters = showButtons && <RenderFilters {...{toggleForm}} />

            const buttonProps = {
                Filters,
                showForm,
            }

            return <Component {...rest} {...buttonProps} />
        }
    )
}

function RenderFilters ({ toggleForm }) {
    const width = 156
    const gap = 15
    const marginTop = -4
    const minHeight = 46

    const buttonStyle = {minHeight,width}
    const overlayStyle = {minHeight,width}
    const selectStyle = {height:'100%',width:'100%'}
    const wrapperStyle = {background:'#fff',display:'flex',gap,justifyContent:'space-between',marginTop,padding:`${gap}px ${gap}px 0 ${gap}px`}

    const filterOverlay = <><Icon>tune</Icon> Filtruj</>
    const selectOverlay = <><Icon>swap_vert</Icon> Sortuj</>

    return (
        <div style={wrapperStyle}>
            <NavLinkButton color="border" onClick={toggleForm} style={buttonStyle}>
                {filterOverlay}
            </NavLinkButton>

            <NavLinkButtonOverlay color="border" overlay={selectOverlay} style={overlayStyle}>
                <Sort dontHideOnMobile={true} label={null} selectStyle={selectStyle} />
            </NavLinkButtonOverlay>
        </div>
    )
}