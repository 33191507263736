import { useState, useEffect } from 'react'
import useWindowWidth from '@hooks/useWindowWidth'

const withMobileOrDesktopInputSelector = (Renderer) => {
    const MobileOrDesktopInputSelector = (props) => {
        const { inputs, mobileInputs, ...rest } = props
        const [onMobile, setOnMobile] = useState(true)
        const width = useWindowWidth()

        useEffect(() => {
            const mobile = width < 1151
            if (mobile !== onMobile) setOnMobile(mobile)
        }, [width])

        return <Renderer {...rest}
            key={onMobile
                ? 'mobile'
                : 'desktop'}

            inputs={onMobile
                ? mobileInputs
                : inputs} />
    }

    return MobileOrDesktopInputSelector
}

export default withMobileOrDesktopInputSelector