import writePriceSlug from '../slug/writePriceSlug'
import writeMileageSlug from '../slug/writeMileageSlug'
import readPriceSlug from '../slug/readPriceSlug'

const SEARCH_PARAM_SLUG_FUNCTIONS = {
    'cena-od': {
        toSlug: writePriceSlug,
        reverse: readPriceSlug,
    },

    'cena-do': {
        toSlug: writePriceSlug,
        reverse: readPriceSlug,
    },

    'przebieg-od': {
        toSlug: writeMileageSlug,
        reverse: parseInt,
    },

    'przebieg-do': {
        toSlug: writeMileageSlug,
        reverse: parseInt,
    },
}

export default SEARCH_PARAM_SLUG_FUNCTIONS