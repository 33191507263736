import withBreakpoints from '@hoc/withBreakpoints'

export default function hideOnMobile (Component) {
    return withBreakpoints({ onMobile: 800 })(
        function Hide ({ breakpoints: { onMobile }, dontHideOnMobile, ...rest }) {
            return onMobile && !dontHideOnMobile
                ? null
                : <Component {...rest} />
        }
    )
}