import { useEffect, useRef } from 'react'
import Link from 'next/link'
import useT from "@hooks/useTranslation"
import { ICON_DIMEN } from '../formLinks'

import styles from 'styles/FormLink.module.css'

const FormLink = ({ i, t, to, svg, alt, title, active }) => {
    const imgAlt = alt || t
    const imgSrc = `/svg/${svg}.svg`
    const isActive = i === active
    const linkTitle = title || alt || t
    const linkRef = useRef(null)

    useEffect(() => {
        if (isActive && linkRef.current) {
            moveScrollWithoutChangingFocus(linkRef.current)
        }
    }, [isActive])

    return (
        <Link href={to} prefetch={false}>
            <a className={isActive
                    ? styles.active_link
                    : styles.link}
                ref={linkRef}
                title={linkTitle}>

                { svg && <img {...ICON_DIMEN}
                    src={imgSrc}
                    alt={imgAlt}
                /> }

                { useT(t) }
            </a>
        </Link>
    )
}

function moveScrollWithoutChangingFocus (ref) {
    try {
        const dimensions = ref.getBoundingClientRect()
        ref.parentElement.scrollLeft = dimensions.left

    } catch (err) {
        console.log(err)
    }
}

export default FormLink