import { useEffect } from 'react'
import Form from '@components/form/Form'
import RenderOutInputs from './RenderOutInputs'
import useActionBar from './useActionBar'
import SearchInputWrapper from './SearchInputWrapper'
import useFormDataKeeper from '@hooks/useFormDataKeeper'
import withMobileOrDesktopInputSelector from './helper/withMobileOrDesktopInputSelector'

import _styles from 'styles/SearchForm.module.css'

const SearchForm = ({ onInit, onSubmit, dataKey,
    inputs, actionBar, overrideDefaultValues, styles = _styles }) => {

    const [formDataKeeper, getFormState] = useFormDataKeeper(dataKey)

    useEffect(() => {
        if (onInit) onInit()
    }, [onInit])

    return (
        <Form
            useActionBar={ actionBar
                ? useActionBar
                : null }

            submitButtonProps={actionBar}
            onSubmit={onSubmit}
            defaultValues={getFormState()}
            overrideDefaultValues={overrideDefaultValues}
            dataKeeper={formDataKeeper}
            className={styles.search_form}
            role="search">

            <RenderOutInputs
                inputs={inputs}
                Wrapper={SearchInputWrapper} />
        </Form>
    )
}

export default withMobileOrDesktopInputSelector(SearchForm)