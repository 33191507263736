import { useEffect } from 'react'
import { Children, createElement } from 'react'
import { useForm } from 'react-hook-form'
import useEnter from '@hooks/useEnter'
import areDifferentObjects from '@utils/object/areDifferentObjects'
import withBreakpoints from '@hoc/withBreakpoints'

let i = 0
const Form = ({ breakpoints, useActionBar, submitButtonProps = {}, onSubmit,
    defaultValues, children, className = '', dataKeeper = null,
    overrideDefaultValues, role, SubmitButton }) => {

    const methods = useForm({ defaultValues })
    const { handleSubmit } = methods
    const execSubmit = handleSubmit(onSubmit)

    const formRef = useEnter(() => {
        if (document.activeElement) {
            document.activeElement.blur()
        }
        execSubmit()
    })

    useEffect(() => {
        if (overrideDefaultValues) {
            const currentValues = methods.getValues()
            if (areDifferentObjects(overrideDefaultValues, currentValues)) {
                methods.reset(overrideDefaultValues, {
                    errors: true,
                    dirtyFields: true,
                    dirty: true,
                    isSubmitted: true,
                    touched: true,
                    isValid: true,
                    submitCount: true,
                })
            }
        }
    }, [overrideDefaultValues])

    const onChange = e => {
        const { name, value } = e.target || e
        if (dataKeeper) dataKeeper({ [name]: value })
    }

    methods.onChange = onChange

    const { allOptionsAsInput } = submitButtonProps || {}

    const actionBarProps = useActionBar && {
        ...submitButtonProps,
        breakpoints,
        defaultValues: overrideDefaultValues || defaultValues,
        dataKeeper,
        execSubmit,
        methods,
    }

    const [actionBar, allActionBarOptionsButton] = useActionBar ? useActionBar(actionBarProps) : []

    return (
        <form onSubmit={execSubmit} className={className} ref={formRef} role={role}>
            {Children.map(children, child => (
                child.props.nomethods
                    ? child
                    : createElement(child.type, {
                        ...{
                            ...child.props,
                            onChange: dataKeeper
                                ? onChange
                                : undefined,
                            register: methods.register,
                            methods,
                            key: child.props.name || i++,
                        }
                    })
            ))}

            { allOptionsAsInput && allActionBarOptionsButton }
            { actionBar }

            { SubmitButton && <SubmitButton {...submitButtonProps}
                defaultValues={overrideDefaultValues || defaultValues}
                dataKeeper={dataKeeper}
                execSubmit={execSubmit}
                methods={methods} /> }
        </form>
    )
}

export default withBreakpoints({ mobile: 1150 })(Form)