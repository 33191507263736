import { useState } from 'react'
import FuelInput from '@components/form/FuelInput'
import FuelInputMobile from '@components/form/FuelInputMobile'
import ActionBarAdvancedOptions from './ActionBarAdvancedOptions'
import DynamicConfigSearchButton from '@components/configsearch/nav/DynamicConfigSearchButton'
import Icon from '@components/shared/Icon'
import useT from '@hooks/useTranslation'

import styles from 'styles/SearchActionBar.module.css'
import linkStyles from 'styles/NavLinkColor.module.css'

const useActionBar = ({ allOptions = true, breakpoints, clear, execSubmit,
    paliwo, advancedOptions, advancedOptionsMobile,
    defaultValues, dataKeeper, methods }) => {

    // state
    const { reset, register, setValue } = methods
    const [lastFormEventTime, setLastFormEventTime] = useState(Date.now())
    const [viewAdvancedOptions, setViewAdvancedOptions] = useState(false)

    // variables
    const isMobile = breakpoints.mobile
    const usableAdvancedOptions = isMobile
        ? advancedOptionsMobile || advancedOptions
        : advancedOptions
    const hasAdvancedOptions = Boolean(usableAdvancedOptions)

    // methods
    const clearForm = (e) => {
        e.preventDefault()
        dataKeeper.purge()
        reset({})
        setLastFormEventTime(Date.now())
    }

    const triggerAdvanced = (e) => {
        e.preventDefault()
        setViewAdvancedOptions(!viewAdvancedOptions)
    }

    const closeAndSend = (e) => {
        e.preventDefault()
        setViewAdvancedOptions(false)
        if (execSubmit) execSubmit()
    }

    // buttons
    const openAdvancedOptionsButton = hasAdvancedOptions
        ? <Button
            t="Wszystkie opcje"
            icon={viewAdvancedOptions
                ? 'expand_more'
                : 'chevron_right'}
            trigger={triggerAdvanced} />
        : null

    const clearButton = <Button
        t="Wyczyść"
        icon="close"
        trigger={clearForm} />

    const configSearchButton = <DynamicConfigSearchButton
        dataKey={dataKeeper.dataKey}
        defaultValues={defaultValues}
        storageKey={dataKeeper.storageKey}
        className={styles.button} />

    const returnButton = isMobile
        ? <Button
            t="Powrót"
            icon="chevron_right"
            trigger={triggerAdvanced} />
        : null

    const submitButton = <Submit onClick={closeAndSend} />
    // styles
    const wrapperClassName = paliwo
        ? styles.action_bar
        : `${styles.action_bar} ${styles.no_fuel}`

    const actionBar = (
        <div className={wrapperClassName}>
            { paliwo && <div className={(isMobile ? styles['mobile-fuels'] : styles.fuels)}>

                { isMobile
                    ? <FuelInputMobile
                        methods={methods}
                        paliwo={paliwo}
                        register={register} />

                    : <FuelInput
                        name="paliwo"
                        defaultValue={defaultValues.paliwo}
                        options={paliwo}
                        register={register}
                        setValue={setValue}
                        dataKeeper={dataKeeper}
                        lastFormEventTime={lastFormEventTime} /> }
            </div> }

            <div className={styles.actions}>
                { clear && clearButton }
                { allOptions && openAdvancedOptionsButton }
                { submitButton }
            </div>

            {
                viewAdvancedOptions && (
                    <ActionBarAdvancedOptions
                        advancedOptions={usableAdvancedOptions}
                        breakpoints={breakpoints}
                        lastFormEventTime={lastFormEventTime}
                        methods={methods}>

                        { configSearchButton }
                        { clear && clearButton }
                        { returnButton }
                        { submitButton }
                    </ActionBarAdvancedOptions>
                )
            }
        </div>
    )

    return [actionBar, openAdvancedOptionsButton, clearButton, submitButton, configSearchButton]
}

function Button ({ t, icon, trigger }) {
    return (
        <button className={styles.button} onClick={trigger} data-all-options="true">
            { useT(t) }
            <Icon>{icon}</Icon>
        </button>
    )
}

function Submit (props) {
    return (
        <button
            type="submit"
            title="Szukaj ogłoszeń samochodowych"
            className={`${linkStyles.navlink} ${linkStyles.teal}`}
            {...props}>

            { useT('Szukaj') }
            <Icon>arrow_right_alt</Icon>
        </button>
    )
}


export default useActionBar