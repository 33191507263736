const createRouterCallback = (router, queryField, resetPage = true) => {
    const pathname = router.pathname
    const query = {...router.query}
    const onChange = ({target:{value}}) => {
        if (resetPage) delete query['strona']
        if (value) query[queryField] = value
        else delete query[queryField]

        router.push({ pathname, query })
    }

    return onChange
}

export default createRouterCallback