import { useState } from 'react'
import FormLinks from './FormLinks'
import Icon from '@components/shared/Icon'
import useT from '@hooks/useTranslation'
import { ICON_DIMEN } from '../formLinks'

import styles from 'styles/FormLink.module.css'
import dropdownStyles from 'styles/FormLinkDropdown.module.css'

const FormLinkDropdown = ({ active, dropdownLinks, noicon }) => {
    if (!Array.isArray(dropdownLinks) || dropdownLinks.length < 1) return null
    const [isOpen, setIsOpen] = useState(false)
    const onClick = () => setIsOpen(!isOpen)
    return (
        <div className={dropdownStyles.position_wrapper}>
            <button onClick={onClick}
                className={`${styles.link} ${styles.buttonlink}`}>

                { ButtonImage(noicon) }
                { useT('Pozostałe') }
                { Chevron(isOpen) }

            </button>

            <DropdownWrapper isOpen={isOpen}>
                <FormLinks
                    active={active}
                    links={dropdownLinks}
                    show={isOpen} />
            </DropdownWrapper>
        </div>
    )
}

function ButtonImage (noicon) {
    return noicon
        ? null
        : <img {...ICON_DIMEN}
            src="/svg/pozostale.svg"
            alt="Pozostałe" />
}

function Chevron (isOpen) {
    return <Icon>{ isOpen
        ? 'expand_more'
        : 'chevron_right' }</Icon>
}

function DropdownWrapper ({ isOpen, children }) {
    return isOpen
        ? (
            <aside className={dropdownStyles.dropdown}>
                { children }
            </aside>
          )

        : null
}

export default FormLinkDropdown
