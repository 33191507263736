const REGEXP = /^(\d+-mln-?)?(\d+-tys)?-zl$/

export default function readPriceSlug (slug) {
    if (!isNaN(slug)) return slug
    const regMatch = String(slug).match(REGEXP)
    if (!regMatch) return slug

    let milions = regMatch[1]
    let thousands = regMatch[2]

    milions = parseInt(milions || 0) * 1000000
    thousands = parseInt(thousands || 0) * 1000

    return milions + thousands
}